.lSPager.lSGallery {
  margin-top : 24px !important;
}

.lSSlideOuter {
  background: #fafafa;
}

.awards {
  position : absolute;
  z-index: 2;
  width: 20%;
  img {
    width: 100%;
  }
}

.awards-list {
  ul {
    list-style: none;
  }

  .list-icon {
    height: .5em;
    width: .5em;
    margin-right: 0.5em;
  }
}

div[data-contact-guide] {
  height: 3em;
}



.row.guide-info {
  aspect-ratio : 4;
  background: #FFAB5A;

}

.row-guide-wrapper {

  &:nth-child(1n) {
    .row.guide-info {
      background: #FFAB5A;

    }
  }

  &:nth-child(2n) {
    .row.guide-info {
      background: #FF6546 !important;

    }
  }

  &:nth-child(3n) {
    .row.guide-info {
      background: #169BD5 !important;
    }
  }

  &:nth-child(4n) {
    .row.guide-info {
      background: #8EC2A3 !important;
    }
  }


}
