.color-white {
  color : white;
}

.background-centered {
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.background-cover {
  background-size : cover !important;
}

.alert-transparent {
  opacity: .5;
}

.snippet {
  font-size : 12px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.snippet.first { border-right : 1px solid black}
.snippet.second {  border-right : 1px solid black}

.btn a {
  color : white;
}

.relative {
  position : relative;
}

.unestyled-ul {
  list-style-type: none !important;
}

body {
  color : black;
  max-width: 100vw;
  overflow-x: hidden;
}

.hover-link {
  transition: all .33s;
  &:hover {
    color : $branding-color;
    filter: saturate(1.5);
    transition: all .33s;
  }
}

button a {
  text-decoration: none !important;
}

.bx-next, .bx-prev {
  &:hover {
    opacity:.75!important;
    transition: opacity .33s;
    background-position: center center !important;
  }
}

.grey-link {
  color : #444;
  &:hover {
    color : $branding-color-secondary;
  }
}


