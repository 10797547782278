$violet-light : #DAC8E5;
$violet-dark : #75339E;

.tour-page {

  .tour-attributes {

    padding : 1em;

    label {
      color: #333333;
      font-weight: bolder;
      text-align: center;
      margin-bottom: .5em;
    }

    .col {
      &:nth-child(1) { background: $branding-color-yello };
      &:nth-child(2) { background: $branding-color-orange };
      &:nth-child(3) { background: $branding-color-green };
      &:nth-child(4) { background: $branding-color-blue };
    }

    p {
      word-break: break-word;
      font-size : .8rem;
      line-height: 1em;
      color: #333;
    }

  }

  .tour-section {
    color : $branding-color-secondary;
    padding : .5em;
    border-bottom : 1px solid $branding-color-secondary;
  }

  .tour-reservation {
    border-radius : 1em;
    background-color: #F2F2F2;
  }

  .reservation-contact {
    margin-top : 1em;
    text-align: center;
    >span, >div {
      display : inline-table !important;
      height : auto !important;
    }

    >div>button {
      //border :1px solid red !important;
      padding: 0;
      display: block !important;
      margin: 0 !important;
      background: transparent !important;
      font-weight: bolder;
      color : $branding-color !important;
    }

  }

  @media screen and (min-width: 1280px) {
    .tour-reservation {
      //position: fixed;
      width: 380px;
      z-index: 999;
    }
  }

}


.reservation-date-picker {
  position : relative;
  z-index: 4;
  .calendar-icon {
    position: absolute;
    right: .6em;
    top: .5em;
    z-index: 3;
  }
}
