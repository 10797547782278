footer{

  font-family: Verdana;

  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;

  .footer-title {
    color : white;
    font-weight: bold;
    font-size : 1.3em;
    margin-bottom : 1.5em;
  }

  .social-icons {

    margin-top : 1em;

    a { display: inline; width: auto }

    i {
      color : #27B4CC;
      font-size: 3em;
      text-shadow: 0 0 2px black;
    }
  }


  .footer-pages {
    ul {
      list-style-type: none;

      a {
        color : black;
        text-decoration: none;
        &:hover {
          color : $branding-color;
          transition: all .33s;
        }
      }

      i {
        font-size: 12px;
        margin-right: 1em;
      }
    }
  }



}
