.homeguides {

  .yellow-col { background-color : #FFAB5A }
  .orange-col { background-color : #FF6546 }
  .blue-col { background-color : #1C93C6 }
  .green-col { background-color : #8EC2A3 }


  .homeguide-col {
    img {
      width : auto;
      height : 4em;
      margin : 1em auto;
    }
  }

}
