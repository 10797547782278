.tour-grid {
  transition : all .33s;
  cursor : pointer;
  &:hover {
    filter: saturate(1.2);
    transition: all .33s;
  }
}
.tour-grid-image {
  height : 12em;
  width: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.tour-grid-name {
  border : 1px solid red !important;
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
  max-lines: 3;
}
.tour-grid-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 7em;
  max-height: 7em;
}
.tour-grid-duration {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 1.8em;
  max-height: 1.8em;
  font-size : .9em;
  padding : .3em;
}

