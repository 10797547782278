@import "variables";

.tcenter {
  display: table !important;
  margin: auto !important;
  float: none !important;
}

.mainblock {
  background : white;
}

.branding-color {
  color : $branding-color
}

.branding-background {
  background : $branding-color
}

.branding-background-2nd {
  background-color: $branding-color-secondary
}

.branding-background-3rd {
  background-color: rgba(255, 171, 90, 1);
}

.branding-background-4rd {
  background-color: rgba(255, 101, 70, 1);
}

.branding-background-5rd {
  background-color: rgba(28, 147, 198, 0.9960)
}

.bg-green {
  background-color: #23990E !important;
  border-color: #23990E !important;
}

.branding-color-secondary  {
  color: #E83450
}

.header-secondary {
  color : $branding-color-secondary ;
  border-bottom : 1px solid  $branding-color-secondary  !important;
  padding-bottom : 1em;
}

.word-wrap {
  word-wrap: break-word;
}

.link {
  &:hover {
    cursor: pointer;
  }
}

.section-title {
  color : $branding-color-secondary;
  padding : .33em;
  border-bottom : 1px solid $branding-color-secondary;
  font-weight: bold;
}

.top-snippet {
  position : absolute;
  right : 0;
  top : 0;
  display: table;
}

.top-snippet-l {
  position : absolute;
  left : 0;
  top : 0;
  display: table;
}

.relative {
  position: relative;
}

.fleft {
  display: table;
  float: left;
}

.hide-tooltip {
  p { display: none !important;}
}


.edit-page > div {
  margin-top : 0 !important;
}
