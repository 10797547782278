.topbar {
  color : rgba(255, 255, 255, 0.7843);
  background: transparent;
  position: relative;
  font-weight: bolder !important;

  a {
    color : white;
    &:hover {
      color : $branding-color;
    }
  }

  .navbar-nav.ms-auto {
    a {
      margin-left: 3vw;
      margin-right: 3vw;
    }
  }

  .img-link {
    height : 1.5em;
  }

  .collapse.show, .collapsing {
    border: 1px solid red;
    position: absolute;
    top: 3em;
    width: 100%;
    left: 0;
    padding: 1em;
    text-transform: uppercase;
    transition: all .33s;
    background-color: $branding-color-secondary !important;
  }

  .top-search-mini {
    width: 20em;
    background: rgba(255, 255, 255, 0.9);
    fieldset {
      border-color : transparent !important
    }

    >div>div>div {
      padding : 0 !important;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none !important;
    }

    label {
      transform: translate(14px, 12px) scale(1);
    }

  }

  .navbar-right .nav-item {
    margin-right : 2em;
  }

  .navbar {
    transition: background .5s;
  }


  .tour-search-mini {
    opacity: 0;
    transition: opacity .2s;
    display: none;
  }

  .scrolled {
    background: $branding-color-secondary;
    transition: all .5s;

    .tour-search-mini {
      opacity: 1;
      display: block;
      transition: all .2s;
    }

    a {
      &:hover {
        color : black;
      }
    }

  }

  @media screen and (min-width:1024px) {
    .navbar {
      padding-top : 1em;
      padding-bottom : 1em;
    }
  }

  .nav-link.active {
    color : black !important;
  }


}
