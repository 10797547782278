.btn.btn-info {
  background: $branding-color;
  color: white;
  border : $branding-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

body, select, input, .top-search * {
  font-family: 'Verdana';
}

b {
  font-family: "Montserrat";
}
