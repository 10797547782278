.post-slider {

  .bx-wrapper {
    box-shadow : none !important;
  }

  .bx-wrapper {
    .bx-prev, .bx-next {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      &:hover {
        filter : saturate(.5);
      }

    }

    .bx-prev {
      left : -2em;
    }

    .bx-next {
      right: -2em;
    }

  }

  .post {
    position: relative;
  }

  .created-by {
    color : white;
    bottom : 1em;
    text-align: center;

  }

  .avatar {
    float: left;
  }

  .created-by-author {

    float:left;
    background: $branding-color-secondary;
    position:relative;
    left: -.5em;
    z-index: 0;
    top : 1em;
    padding : .25em .5em;
    transition: color .33s;

    a:hover {
      color : black !important;
      transition: color .33s;
    }

  }

  .avatar img {
    position:relative;
    z-index : 1;
    height: 4em;
    width: 4em;
    border-radius : 100%;
    border : 4px solid $branding-color-secondary
  }

  .post-title {
    height: calc(100% - 69px);
    color: white;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 1em;
    width: 100%;
    overflow: hidden;
  }

  .post-title-table {
    display: table;
    width: 100%;
    height: 100%;
  }

  .post-title-tr {
    display : table-row;
    height: 100%;
  }

  .post-title-td {
    display : table-cell;
    vertical-align: middle;
    text-shadow: 0 0 3px black;
  }

  .post-bg {
    background-size: 99% 99% !important;
    background-position: center center !important;
    aspect-ratio: 1.25;
    -webkit-aspect-ratio: 1.25;
    background-repeat: no-repeat !important;
  }

  .post {
    aspect-ratio: 1.25;
    -webkit-aspect-ratio: 1.25;

    background-repeat: no-repeat;
    background-size : 100% 100%;
    background-color: transparent;
    max-width: 100% !important;
    width: 100%;
    height: 100%;
  }

  .bx-pager-item {
    display: none;
  }

  .post-title-table a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    max-lines: 4;
    height : 4em;
  }

}

.posts-simple {
  a {
    color : black;
    text-decoration: none;
    &:hover {
      color : $branding-color-secondary;
      transition : all .3s
    }
  }


  .post-excerpt p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .post-excerpt .h6 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .post-bg {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size : cover !important;
    background-color: #fafafa !important;
    aspect-ratio : 1;
  }


  @media screen and (max-width: 700px) {
    .post-excerpt p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

}

.posts-all {

  .fullwidth {
    .post-row:nth-child(odd) {
      background-color: #fdfdfd;
    }

    .post-row:nth-child(even) {
      background-color: #f5f5f5;
    }


  }
}

.post a {
  text-decoration: none;
  color : white;
  &:hover {
    color : $branding-color-secondary;
    transition: all .33s;
  }
}

.post-avatar {
  text-align: center;
  img {
    width : 3em;
    border-radius : 100%;
  }
  .post-created-by, .avatar {
    display: inline;
  }
}

.pagination {

  font-weight: bold;

  margin : 2em auto;
  display : table;
  width: 100%;
  text-align: center;
  padding-bottom: 1em;


  color : $branding-color;

  a {
    color : $branding-color;
    border : 1px solid $branding-color;
    padding : .5em .75em;
    aspect-ratio : 1;
    text-decoration: none;
  }

  .current {
    background: $branding-color;
    border : 1px solid $branding-color;
    color : white;
    padding : .5em .75em;
    aspect-ratio : 1;
  }

}


