.topslider {
  position: relative;
  width: 100%;
  z-index: 0;
  top : -4em;

  background-position: center center !important;

  .page-title {
    font-size : 4rem;
    color: #E83450;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    text-shadow: 2px 0 3px rgba(0, 0, 0, 0.5215);
  }

  .page-subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    color : rgba(255, 255, 255, 0.78);
    font-family: 'Montserrat', sans-serif;
  }

  .topslider-inner {
    backdrop-filter: brightness(.7);
    width: 100%;
  }

  .top-search fieldset {
    border-color: transparent !important;
  }

  .top-search > div {
    background: rgba(255, 255, 255, 0.6);
    border-radius : 4px;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    padding : 0 !important;
  }

  .top-search .MuiInputLabel-animated {
    transform: translate(8px, 10px);
    text-shadow : 0 0 .1px black;

    &[data-shrink="true"] {
      opacity : 0;
      transition: opacity .33s;
    }

  }


  .top-search {
    .MuiInputBase-root {
      padding : 0;
      font-family: Montserrat;
    }
  }

  .btn.btn-info {
    padding: 0.45em;
  }

}

.topslider:not(.topslider-default) .topslider-inner {
  height: 339px;
}
