@import '~mapbox-gl/dist/mapbox-gl.css';

.mapboxgl-ctrl {
  display: none !important;
}

.mapboxgl-popup-content {
  max-width: 380px;
  width: 380px;
  background: transparentize($branding-color-secondary, .1);
  h5 { color: white; }
  p { font-weight: bold; }
  a {
    font-weight: bolder;
    width: 100%;
    text-align: center;
    color : rgba(20, 33, 219, 0.996);
    display: table;
    font-size : 1.1em;
  }

}


.country-selector {
  //border : 1px solid red;
  //* {
  //  border : 1px solid red;
  //
  //}
}
